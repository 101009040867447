.TeamContainer{
    display: flex;
    justify-content: center;
    /* align-items: center; */
    margin-top: 50px;
    margin-bottom: 50px;
    gap: 50px;
}

.TeamCard{
    /* width: 300px;
    height: 300px;
    margin: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    position: relative;
    transition: 0.5s; */
}

@media screen and (max-width: 900px){
    .TeamContainer{
        gap: 0px;
        flex-direction: column;
        /* background-color: red; */
    }
    .TeamContainer :nth-child(1) {
        order: 3;
    }
    .TeamContainer :nth-child(2) {
        order: 1;
    }
    .TeamContainer :nth-child(3) {
        margin-top: 50px;
        order: 2;
    }
}